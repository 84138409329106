import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faTimes, faChevronLeft, faChevronRight, faBars, faWandMagicSparkles, faPhotoFilm } from '@fortawesome/free-solid-svg-icons'
import { loadAllImageURLs, exportCurl, exportImagine, getInFlightIds } from "./SaveUtils";
import { altPreset, applySettings, defaultPreset, summarizeSettings, makeRequest, fetchImageData, pollForResults, prependLoadingGifs } from "./BobUtils";

var allInfo = {};

const sayings = [
    "Get creative!",
    "Make a wish!",
    "Let your imagination run wild!",
    "See what you can come up with!",
    "I'm sure you can think of something great!",
    "How about giving it a try?",
    "I'd love to see what you come up with!",
    "I'm sure you'll come up with something brilliant!",
    "Why not give it a shot?",
    "I'm excited to see what you come up with!",
    "Come up with something amazing!",
    "Get those creative juices flowing!",
    "I bet you can come up with something awesome!",
    "Let's see what you've got!",
    "Show me what you're made of!",
    "I'm sure you'll come up with something incredible!",
    "Can't wait to see what you come up with!",
    "Get ready to be amazed!",
    "I have faith in you!",
    "I know you can do it!",
    "You've got this!",
    "I can't wait to see what you come up with!",
    "This is your chance to shine!",
    "Make us proud!"
];

const prompts = [
    "A worm wearing a top hat, photograph",
    "Self-fulfilling prophecy",
    "Two people in a canoe",
    "Firestorm in the sky",
    "Spikey Mikey",
    "A bowl of tortilla chips",
    "Drawing of a chihuahua",
    "A strawberry wearing sunglasses",
    "Sentient hula hoop",
    "An apple that isn't really an apple",
    "Raining cats and dogs",
    "A harmonica made of spaghetti",
    "Hand-made soap",
    "Realistic drawing of a banana",
    "A great pile of leaves"
];

const randomSaying = sayings[parseInt(Math.random() * sayings.length)];
const randomPrompt = prompts[parseInt(Math.random() * prompts.length)];

var currentFontSize = 30;

export const MainPage = () => {

    // const imageURLs = loadAllImageURLs();
    const models = ["stabilityai/stable-diffusion-xl-base-1.0", "Linaqruf/animagine-xl"];
    const samplers = ["plms", "ddim", "k_lms", "k_dpm_2", "k_dpm_2_a", "k_euler", "k_euler_a", "k_heun"];

    const [imgs, setImgs] = useState([]);
    const [hoveredImageJob, setHoveredImageJob] = useState(null);
    const [footerInfo, setFooterInfo] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // load data from local storage on page load
        applySettings();
        let curImgs = loadAllImageURLs();
        curImgs = prependLoadingGifs(curImgs);
        setImgs(curImgs);
        // start polling for in-flight images
        const inFlightIds = getInFlightIds();
        for (let id of inFlightIds) {
            pollForResults(id, setImgs);
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            // when hovered image changes, update the tooltip
            if (hoveredImageJob && !allInfo[hoveredImageJob]) {
                allInfo[hoveredImageJob] = await fetchImageData(hoveredImageJob);
            }
            setFooterInfo(allInfo[hoveredImageJob]);
        }
        fetchData();
    }, [hoveredImageJob]);

    return (
        <div className="wholePage">
            <div className="content">
                <div className="header">
                    <div className="logo">
                        ArtFelt.AI{process.env.REACT_APP_DEV_MODE ? " <dev>" : ""}
                    </div>
                    <ul>
                        <li><a href="rankings">Leaderboard</a></li>
                        <li><a href="setup">Setup</a></li>
                        <li><a href="about">FAQ</a></li>
                    </ul>
                    <div className="expander" onClick={() => {
                        document.querySelector(".header ul").classList.toggle("show");
                    }}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
                <div className="overview">
                    <div className="main">
                        <div className="blurb">
                            Create AI art instantly!
                            <div>ArtFelt is a free service powered by donated GPU time</div>
                        </div>
                        <div className="mainPrompt">
                            <input
                                type="text"
                                id="prompt"
                                placeholder={randomPrompt}
                                onBlur={summarizeSettings}
                            />
                        </div>
                        <div className="submitArea">
                            <button id="mainSubmit" onClick={() => {
                                    const settings = summarizeSettings();
                                    for (let x = 0; x < settings.count; x++) {
                                        makeRequest(setImgs);
                                    }
                                    // scroll sidebar to the top
                                    document.getElementsByClassName("Sidebar")[0].scrollTop = 0;

                                    // force sidebar to be shown (even on mobile)
                                    setIsOpen(true);
                                    document.getElementsByClassName("Sidebar")[0].classList.add("show");
                                    const pullTab = document.getElementsByClassName("PullTab")[0];
                                    pullTab.style.marginRight = isOpen ? "0px" : "250px";

                                }}>Try It!</button>
                        </div>
                        <div className="chooser">
                            <div id="choice1" className="chooserItem chosen" onClick={() => {
                                applySettings(defaultPreset());
                                document.getElementById("choice1").classList.add("chosen");
                                document.getElementById("choice2").classList.remove("chosen");
                            }}>
                                <FontAwesomeIcon icon={faPhotoFilm} size={"2x"} />
                                <div className="chooserItemTitle">Default</div>
                                {/* <div className="chooserItemDesc">Great for most use-cases</div> */}
                            </div>
                            <div id="choice2" className="chooserItem" onClick={() => {
                                applySettings(altPreset());
                                document.getElementById("choice1").classList.remove("chosen");
                                document.getElementById("choice2").classList.add("chosen");
                            }}>
                                <FontAwesomeIcon icon={faWandMagicSparkles} size={"2x"} />
                                <div className="chooserItemTitle">Anime</div>
                                {/* <div className="chooserItemDesc">Optimized for anime-style images</div> */}
                            </div>
                        </div>
                        <div className="SettingsUI">
                            <div>
                                {/* checkbox for advanced settings */}
                                <button
                                    id="advanced"
                                    onClick={() => {
                                        const advanced = document.getElementById("Advanced");
                                        const curStyle = advanced.style.display
                                        advanced.style.display = curStyle != "block" ? "block" : "none";
                                        document.getElementById("advanced").innerHTML = curStyle != "block" ? "Hide Additional Options" : "Show Additional Options";
                                    }}
                                >Show Additional Options</button>
                            </div>
                            <div id="Advanced">
                                <div className="form-group">
                                    <label>Negative Prompt</label>
                                    <input
                                        type="text"
                                        id="negative_prompt"
                                        placeholder={"Enter some terms to avoid in the image, leave blank for default"}
                                        onBlur={summarizeSettings}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Seed</label>
                                    <input type="number" id="seed" defaultValue="" placeholder="leave empty for random" onBlur={summarizeSettings} />
                                </div>
                                <div className="form-group">
                                    <label>Count</label>
                                    <input type="number" id="count" min="1" max="10" defaultValue="1" onBlur={summarizeSettings} />
                                </div>
                                <div className="form-group">
                                    <label>Model Name</label>
                                    <input type="text" id="model" disabled />
                                </div>
                                {/* <div className="form-group">
                                    <label>Dimensions</label>
                                    <input type="number" id="width" defaultValue="512" onBlur={summarizeSettings} /> x
                                    <input type="number" id="height" defaultValue="512" onBlur={summarizeSettings} />
                                </div>
                                <div className="form-group">
                                    <label>Steps</label>
                                    <input type="number" id="steps" defaultValue="50" onBlur={summarizeSettings} />
                                </div>
                                <div className="form-group">
                                    <label>Sampler Type</label>
                                    <select id="sampler_type">
                                        {samplers.map((sampler, idx) => <option key={idx} value={sampler}>{sampler}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Bools</label>
                                    <input id="tile" type="checkbox" />&nbsp;<span>Tile</span>&nbsp;|&nbsp;
                                    <input id="upscale" type="checkbox" />&nbsp;<span>Upscale</span>&nbsp;|&nbsp;
                                    <input id="fix_faces" type="checkbox" />&nbsp;<span>Fix Faces</span>
                                </div>
                                <div className="form-group">
                                    <label>Strength</label>
                                    <input type="number" id="strength" min="0" max="10" defaultValue="7.5" onBlur={summarizeSettings} />
                                </div> */}
                                <div className="form-group">
                                    <label>Export</label>
                                    <button onClick={exportCurl}>CURL</button>
                                    <button onClick={exportImagine}>CLI</button>
                                </div>
                                <div className="form-group">
                                    <label>Debug</label>
                                    <button onClick={() => {
                                        localStorage.removeItem("inFlightIds");
                                        window.location.reload();
                                    }}>Clear InFlight</button>
                                    <button onClick={() => {
                                        localStorage.removeItem("imgIds");
                                        window.location.reload();
                                    }}>Clear Images</button>
                                    <button onClick={() => {
                                        localStorage.removeItem("settings");
                                        window.location.reload();
                                    }}>Clear Settings</button>
                                </div>
                            </div>
                        </div>
                        <div className="tip">
                            <FontAwesomeIcon icon={faLightbulb} />
                            {randomSaying}
                        </div>
                        <div className="realFooter">
                            Terms of Use - Become a Runner - Privacy Policy
                        </div>
                    </div>
                </div>
                {footerInfo && (
                    <div className="footer">
                        <span>{footerInfo}</span>
                        <FontAwesomeIcon icon={faTimes} onClick={() => setFooterInfo(null)} />
                    </div>
                )}
            </div>
            <div className="Sidebar">
                <div className="ImageList reel">
                    {
                        imgs.map((imgData, idx) => {
                            let imgUrl = imgData;
                            let jobId = null;
                            if (typeof imgData === "object") {
                                ([imgUrl, jobId] = imgData);
                            }

                            return (
                                <div key={idx} className="ImageListItem">
                                    <a target="_blank" href={imgUrl} alt="Generated Image">
                                        <img
                                            src={imgUrl}
                                            onMouseOver={() => setHoveredImageJob(jobId || imgUrl)}
                                        />
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="PullTab" onClick={() => {
                setIsOpen(!isOpen);
                document.getElementsByClassName("Sidebar")[0].classList.toggle("show");
                const pullTab = document.getElementsByClassName("PullTab")[0];
                pullTab.style.marginRight = isOpen ? "0px" : "250px";

            }}>
                <FontAwesomeIcon icon={isOpen ? faChevronRight : faChevronLeft} />
            </div>

            <div className="SidebarShadow"></div>
        </div>
    );
};
