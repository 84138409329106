import './App.css';
import { MainPage } from './MainPage';

function App() {
  return (
    <MainPage />
  );
}

export default App;
