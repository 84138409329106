import {
    loadSettings,
    saveSettings,
    saveImageData,
    loadAllImageURLs,
    getInFlightCount,
    addInFlightId,
    removeInFlightId
} from "./SaveUtils";

export let BACKEND_URL = "https://artfelt.ai/jobs";

// check env variable for local backend
if (process.env.REACT_APP_DEV_MODE) {
    BACKEND_URL = "http://localhost/jobs";
}

export const defaultPreset = () => {
    return {
        model: "stabilityai/stable-diffusion-xl-base-1.0",
        width: 1024,
        height: 1024,
        steps: 50,
        sampler_type: "plms",
        tile: false,
        upscale: false,
        fix_faces: false,
        strength: 7.5,
        count: 1,
        seed: "",
        preset: "default",
        negative_prompt: ""
    };
};

export const altPreset = () => {
    return {
        model: "Linaqruf/animagine-xl",
        width: 1024,
        height: 1024,
        steps: 35,
        sampler_type: "k_euler_a",
        tile: false,
        upscale: false,
        fix_faces: false,
        strength: 7.5,
        count: 1,
        seed: "",
        preset: "alt",
        negative_prompt: ""
    };
};

export const summarizeSettings = () => {
    let settings = {};
    settings.prompt = document.getElementById("prompt").value;
    settings.model = document.getElementById("model").value;
    settings.count = document.getElementById("count").value;
    settings.negative_prompt = document.getElementById("negative_prompt").value;
    // settings.width = parseInt(document.getElementById("width").value);
    // settings.height = parseInt(document.getElementById("height").value);
    // settings.steps = parseInt(document.getElementById("steps").value);
    // settings.sampler_type = document.getElementById("sampler_type").value;
    // settings.tile = document.getElementById("tile").checked;
    // settings.upscale = document.getElementById("upscale").checked;
    // settings.fix_faces = document.getElementById("fix_faces").checked;
    // settings.strength = parseFloat(document.getElementById("strength").value);
    settings.seed = parseInt(document.getElementById("seed").value);
    // settings.preset = document.getElementById("style-preset").value;
    saveSettings(settings);
    if (settings.seed == "") {
        // blank means leave out the seed
        delete settings.seed;
    }
    return settings
}

export const applySettings = (incoming) => {
    let settings = incoming || loadSettings();
    if (settings.prompt !== undefined) {
        document.getElementById("prompt").value = settings.prompt;
    }
    if (settings.count !== undefined) {
        document.getElementById("count").value = settings.count;
    }
    document.getElementById("model").value = settings.model;
    document.getElementById("negative_prompt").value = settings.negative_prompt;
    // document.getElementById("width").value = settings.width;
    // document.getElementById("height").value = settings.height;
    // document.getElementById("steps").value = settings.steps;
    // document.getElementById("sampler_type").value = settings.sampler_type;
    // document.getElementById("tile").checked = settings.tile;
    // document.getElementById("upscale").checked = settings.upscale;
    // document.getElementById("fix_faces").checked = settings.fix_faces;
    // document.getElementById("strength").value = settings.strength;
    document.getElementById("seed").value = settings.seed;

    // document.getElementById("style-preset").value = settings.preset;
};

export const rmDefaults = (settings) => {
    // remove any settings that are the same as the default
    let defaults = defaultPreset();
    for (let key in defaults) {
        console.log(key, settings[key], defaults[key]);
        if (key == "model") {
            continue; // always send model
        }
        if (key == "fix_faces") {
            continue; // always send fix_faces (our server defaults to true)
        }
        if (defaults[key] == settings[key]) {
            delete settings[key];
        }
    }
    return settings;
};

export const prependLoadingGifs = (curImgs) => {
    const inFlightCount = getInFlightCount();
    if (inFlightCount > 0) {
        // insert loading gifs for the number of inflight reqs at the front
        // to accurately maintain the number of images we're waiting on
        let loadingGifs = [];
        for (let i = 0; i < inFlightCount; i++) {
            loadingGifs.push("loading.png");
        }
        curImgs = [...loadingGifs, ...curImgs];
    }
    return curImgs;
};

export const pollForResults = async (jobId, setImgs) => {
    // poll for results
    let jobComplete = false;
    let cdnImageURL = "";

    while (true) {
        if (jobComplete) {
            // we've already seen the job complete, so we're just waiting for the images to be ready
            const res = await fetch(cdnImageURL);
            if (res.ok) {
                // the image exists! let's update the UI
                // (we already saved the image previously)

                let curImgs = loadAllImageURLs();
                // prepend any loading gifs
                curImgs = prependLoadingGifs(curImgs);
                // update the UI
                setImgs(curImgs);

                // finally exit the polling loop
                break;
            }
            // image still not ready yet, wait and re-check
            await new Promise(r => setTimeout(r, 1000));
            continue;
        }

        const res = await fetch(`${BACKEND_URL}/${jobId}`);
        const json = await res.json();

        if (json.status == "completed") {
            // save the new images
            const { result: { url } } = json;
            cdnImageURL = url;
            saveImageData(cdnImageURL, jobId);
            // remove the job id from the in flight list
            removeInFlightId(jobId);
            // the job completed, but the image may not be ready yet
            // we've saved the image URL already, but have to keep polling
            jobComplete = true;
            continue;
        } else if (json.status == "created" || json.status == "claimed") {
            // we're going to keep looping, wait for half a second
            await new Promise((r) => setTimeout(r, 500));
        } else {
            // we have a problem brother
            console.log("error", json);
            // TODO: do we remove the inflight ID? I think probably yes?
            // or display some kind of error entry in the list
            alert("There was an error processing your request" + json);
            removeInFlightId(jobId);
            break;
        }
    }
};

export const makeRequest = async (setImgs) => {
    const settings = rmDefaults(summarizeSettings());
    let url = `${BACKEND_URL}`;
    let req = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(settings)
    };

    // TODO: display some indicator that we're waiting for the job to be accepted
    // this should be quick, but might as well let the user know

    try {
        const resp = await fetch(url, req);
        if (!resp.ok) {
            throw new Error("Got: " + resp.status + " - " + (await resp.text()));
        }

        // get the job ID out of the details, and use it to poll for the results
        const { id } = await resp.json();
        addInFlightId(id);
        // kicks off async polling for this job
        pollForResults(id, setImgs);
    } catch (err) {
        let errStr = err.toString()
        console.log(errStr);
        alert(errStr);
    }

    // reload all images (localStorage is thread-safe)
    let curImgs = loadAllImageURLs();
    curImgs = prependLoadingGifs(curImgs);
    setImgs(curImgs);
};

export const fetchImageData = async (jobIdOrUrl) => {
    let jobId = jobIdOrUrl;
    if (!jobId || jobId === "loading.png") {
        return "";
    } else if (jobId.endsWith(".jpg")) {
        jobId = jobIdOrUrl.split("/").pop().replace(".jpg", "");;
    }
    // get last segment of url path
    const resp = await fetch(`${BACKEND_URL}/${jobId}`);
    const jobData = await resp.json();
    const { job_details } = jobData;
    let humanData = "";
    // go through all key values and add them to the humanData string
    for (let key in job_details) {
        humanData += key + ": " + job_details[key] + "  |  ";
    }
    if (humanData.endsWith("  |  ")) {
        humanData = humanData.slice(0, -5);
    }
    return humanData;
}
