// who needs a database when you have helper functions like these?

import { defaultPreset, BACKEND_URL, summarizeSettings, rmDefaults } from "./BobUtils";

const IMG_KEYS = 'imgIds';
const SETTINGS_KEY = 'settings';

const localStorage = window.localStorage;

export const loadAllImageURLs = () => {
    // get all image IDs from local storage
    const ImageURLs = JSON.parse(localStorage.getItem(IMG_KEYS)) || [];
    return ImageURLs;
};

export const saveImageData = (newImageURL, jobId) => {
    // get current image IDs from local storage
    const imageURLs = loadAllImageURLs();
    // save updated array to local storage
    const imgData = [newImageURL, jobId];
    // add new image data to array
    imageURLs.unshift(imgData);
    localStorage.setItem(IMG_KEYS, JSON.stringify(imageURLs));
};

export const saveSettings = (settings) => {
    // save settings to local storage
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

export const loadSettings = () => {
    // load settings from local storage
    const settings = JSON.parse(localStorage.getItem(SETTINGS_KEY)) || defaultPreset();
    if (!settings.count) {
        settings.count = 1;
    }
    return settings;
};

export const addInFlightId = (id) => {
    // get current in-flight IDs from local storage
    const inFlightIds = getInFlightIds();
    // add new in-flight ID to array
    inFlightIds.push(id);
    // save updated array to local storage
    localStorage.setItem('inFlightIds', JSON.stringify(inFlightIds));
};

export const removeInFlightId = (id) => {
    // get current in-flight IDs from local storage
    const inFlightIds = getInFlightIds();
    // remove this one
    const newInFlightIds = inFlightIds.filter((inFlightId) => inFlightId !== id);
    // save updated array to local storage
    localStorage.setItem('inFlightIds', JSON.stringify(newInFlightIds));
};

export const getInFlightIds = () => {
    // get current in-flight IDs from local storage
    const inFlightIds = JSON.parse(localStorage.getItem('inFlightIds')) || [];
    return inFlightIds;
};

export const getInFlightCount = () => {
    return getInFlightIds().length;
};

export const exportCurl = () => {
    const settings = rmDefaults(summarizeSettings());
    if (settings.count) {
        delete settings.count;
    }
    if (!settings.seed) {
        delete settings.seed;
    }
    // export curl command to download image
    let curl = `curl -X POST ${BACKEND_URL} --header "Content-Type: application/json" --data "${JSON.stringify(settings).replaceAll("\"", "\\\"")}"`;
    prompt("CURL Command", curl);
    return curl;
};

export const exportImagine = () => {
    // export imagine command to download image
    let parts = ["python3 cli.py"]
    const settings = rmDefaults(summarizeSettings());
    for (const [key, value] of Object.entries(settings)) {
        if (key === "prompt") {
            parts.push(`"${value.replaceAll("\"", "\\\"")}"`);
        } else if (key === "model") {
            // TODO: add support for other models (CLI doesn't support them yet)
            if (value === "Linaqruf/animagine-xl") {
                parts.push("-a");
            }
        } else if (key === "count") {
            parts.push(`-r ${value}`);
        } else if (key === "negative_prompt") {
            parts.push(`-n "${value.replaceAll("\"", "\\\"")}"`);
        } else if (key === "width") {
            // parts.push(`--width ${value}`);
        } else if (key === "height") {
            // parts.push(`--height ${value}`);
        } else if (key === "steps") {
            // parts.push(`--steps ${value}`);
        } else if (key === "sampler_type") {
            // parts.push(`--sampler-type ${value}`);
        } else if (key === "tile" && value) {
            // parts.push(`--tile`);
        } else if (key === "upscale" && value) {
            // parts.push(`--upscale`);
        } else if (key === "seed") {
            // check if value is defined and not null or nan
            if (value && value === value) {
                parts.push(`-s ${value}`);
            }
        } else if (key === "strength") {
            // parts.push(`--prompt-strength ${value}`);
        } else if (key === "fix_faces" && value) {
            // parts.push(`--fix-faces`);
        }
    }
    const imagine = parts.join(" ");
    prompt("Imagine Command", imagine);
    return imagine;
};